import React from 'react'
import Link from 'next/link'

function Noresults({ item }) {
    return (

        <div className="detail-404">
            <div className="wrap">
                {/* <p className="section-label">404 PAGE</p> */}
                <h3 className="font-semibold text-brand-primary mb-4">No Results Found</h3>
                <p>Sorry, we couldn&apos;t find any matches for your search.</p>
                <p className="text-gray-700 mb-5">Try searching for other, related words or <Link href="/contact"><a className="text-brand-primary underline font-semibold hover:no-underline">get in touch with us</a></Link> to get help from one of our expert staff.</p>
                <Link href="/">
                    <a className="btn btn-primary btn-lg w-100 d-block">Back To Homepage</a>
                </Link>
            </div>
        </div>
    );
}

export default Noresults;